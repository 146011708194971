/* LOGO */

:root {
  --background-color: #FFF;
  --base1-color: #A8DADC;
  --base2-color: #457B92;
  --base3-color: #1D3557;
  --accent-color: #E94957;

  --font-regular: 'Lato';
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 3rem;
  }
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('truetype');
  font-style: normal ;
  font-weight:400;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Bold.ttf) format('truetype');
  font-style:normal ;
  font-weight: bold;
}

th.id-cell,
td.id-cell {
  width: 50px !important;
  max-width: 50px !important;
}

div.asset-detail {
  border: 1px solid #dcdce3;
}

table.asset-detail th {
  background-color: #ececf3 !important;
  border: 1px solid #dcdce3;
  width: 20em;
  max-width: 20em;
  white-space: normal;
}

.development {
  display: none;
}

/* ARGON REDEFINED STYLES */

strong {
  font-weight: bold;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #333;
  text-align: left;
  caption-side: top;
  padding-left: 2px;
}

/* React bootstrap table */

.row.react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}

.react-bootstrap-table .table .thead-light th {
  background-color: #f6f9fc;
  color: #444c55;
}

.react-bootstrap-table > table > thead > tr > th .filter {
  font-weight: 400;
  height: 2.2rem;
}

.mdashboard-filter {
  filter: saturate(0) invert(1);
  box-shadow: 0 0 8px 8px white inset;
  mix-blend-mode: screen;
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.column-selector-switch {
  display: inline-block;
  width: 100px;
}

.column-selector {
  /* overflow-y: auto; */
  /* min-height: 30vh; */
  margin: 15px;
  max-width: 100%;
}

.card .table td, .card .table th {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}


