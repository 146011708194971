/*this thing should be styled by css classes and can not use the react style*/


.filepond--drop-label {
    border-style: dashed;
    border-color: var(--base2-color);
    background-color: var(--background-color);
    height: 25vh;
}

.filepond--drop-label label{
  font-family: var(--font-regular);
  font-size: 1.5em;
}

.filepond--drop-label:hover {
  border-color: var(--accent-color);
}

.filepond--label-action {
  color: var(--accent-color);
}


/* the background color of the file and file panel (used when dropping an image) */
.filepond--item-panel {
  background-color: var(--base3-color);
}

.filepond--drip-blob {
  background-color: var(--accent-color);
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: var(--base1-color);
}




/* PAGE STYLES */
.image-reader {
  background-color: var(--background-color);
  border-radius: 3px;
  padding: 30px;
}

.image-reader h2 {
  font-family: var(--font-regular);
  font-size: 2em;
  color: var(--base2-color);
}